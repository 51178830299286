import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyPolicy from "../components/PrivacyPolicy"

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <PrivacyPolicy />
    </Layout>
  )
}

export default PrivacyPolicyPage
